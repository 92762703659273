import React, { memo } from 'react';

import {
	useInProductHelpActions,
	useShouldShowStandardDifferentiators,
} from '@confluence/in-product-help';
import { LoadableLazy } from '@confluence/loadable';

const StandardDifferentiatorsHelp = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-StandardDifferentiatorsHelp" */ '../src/StandardDifferentiatorsHelp'
			)
		).StandardDifferentiatorsHelp,
});

const StandardDifferentiatorsOnboarding = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-StandardDifferentiatorsOnboarding" */ '../src/StandardDifferentiatorsOnboarding'
			)
		).StandardDifferentiatorsOnboarding,
});

export const StandardDifferentiators = memo(() => {
	const inProductHelpContext = useInProductHelpActions();
	const shouldShowStandardDifferentiators = useShouldShowStandardDifferentiators();
	const handleStandardDifferentiatorsHelpClose = () => {
		inProductHelpContext.setShouldShowStandardDifferentiators(false);
	};
	return (
		<>
			{shouldShowStandardDifferentiators ? (
				<StandardDifferentiatorsHelp onClose={handleStandardDifferentiatorsHelpClose} />
			) : null}
			<StandardDifferentiatorsOnboarding />
		</>
	);
});
