import React from 'react';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ConditionalCreateSpaceDrawer } from '@confluence/create-space/entry-points/createSpace';
import { ConditionalCompanyHubBuilderDrawer } from '@confluence/company-hub/entry-points/CompanyHubBuilder';
import {
	ConditionalImportContentModal,
	ConditionalImportContentDrawer,
} from '@confluence/import-content/entry-points/importContent';
import { ForgeGlobalKeyboardShortcuts } from '@confluence/forge-ui';
import { StorageEnforcement } from '@confluence/storage-enforcement/entry-points/StorageEnforcement';
import { OnboardingQuickstart } from '@confluence/onboarding-quickstart';
import { StandardDifferentiators } from '@confluence/experiment-standard-differentiators';
import { RovoVideoModal } from '@confluence/rovo-video-modal/entry-points/RovoVideoModal';
import { LoadableEndOfTrialModal } from '@confluence/end-of-trial-payment-modals/entry-points/LoadableEndOfTrialModal';
import { TrialFlags } from '@confluence/trial-components';
import { LazyRestrictionsDialogMountPoint } from '@confluence/restrictions';

/**
 * Use this component to render global components that should be rendered on every page.
 */
export const GlobalComponents = () => {
	return (
		<>
			<ErrorBoundary
				attribution={Attribution.DISCO}
				attributes={{
					errorBoundaryId: 'GlobalComponents-Disco',
				}}
			>
				<ConditionalCreateSpaceDrawer />
			</ErrorBoundary>

			<ErrorBoundary
				attribution={Attribution.COMPANY_HUB}
				attributes={{
					errorBoundaryId: 'GlobalComponents-CompanyHub',
				}}
			>
				<ConditionalCompanyHubBuilderDrawer />
			</ErrorBoundary>

			<ErrorBoundary
				attribution={Attribution.MIGRATIONS}
				attributes={{
					errorBoundaryId: 'GlobalComponents-Migrations',
				}}
			>
				<ConditionalImportContentModal />
				<ConditionalImportContentDrawer />
			</ErrorBoundary>

			<ErrorBoundary
				attribution={Attribution.ECOSYSTEM}
				attributes={{
					errorBoundaryId: 'GlobalComponents-Ecosystem',
				}}
			>
				<ForgeGlobalKeyboardShortcuts />
			</ErrorBoundary>

			<ErrorBoundary
				attribution={Attribution.ADMIN_EXPERIENCE}
				attributes={{
					errorBoundaryId: 'GlobalComponents-AdminExperience',
				}}
			>
				<StorageEnforcement />
			</ErrorBoundary>

			<ErrorBoundary
				attribution={Attribution.CC_ONBOARDING}
				attributes={{
					errorBoundaryId: 'GlobalComponents-Onboarding',
				}}
			>
				<OnboardingQuickstart />
			</ErrorBoundary>

			<ErrorBoundary
				attribution={Attribution.GROWTH_LUNA}
				attributes={{
					errorBoundaryId: 'GlobalComponents-GrowthLuna',
				}}
			>
				<StandardDifferentiators />
				<RovoVideoModal />
				<LoadableEndOfTrialModal>
					<TrialFlags />
				</LoadableEndOfTrialModal>
			</ErrorBoundary>

			<ErrorBoundary attribution={Attribution.PERMISSIONS_EXPERIENCE}>
				<LazyRestrictionsDialogMountPoint />
			</ErrorBoundary>
		</>
	);
};
